<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="TagDialogTitle"></span>
    </div>
    <!-- tagInfo：{{tagInfo}}
    <hr>
    groupId: {{groupId}}
    <hr>
    formData.title：{{formData.title}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="标签名称：" prop="title">
        <el-input v-model.trim="formData.title" placeholder="请输入标签名称(50个字符以内)"></el-input>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="saveTag">保 存</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { updateTags, addTags } from '@/api/research/tag'

export default {
  props: {
    pageFlag: {
      default: '',
      type: String
    },
    show: {
      default: false,
      type: Boolean
    },
    groupId: {
      default: '',
      type: String
    },
    tagInfo: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      TagDialogTitle: '',
      formData: {
        title: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写标签名称', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: ''
      }
      if (this.tagInfo !== null) {
        this.TagDialogTitle = '编辑标签'
        this.formData.title = this.tagInfo.title
      } else {
        this.TagDialogTitle = '新增标签'
      }
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    //
    saveTag () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.tagInfo !== null) {
            updateTags({
              id: this.tagInfo.id,
              title: this.formData.title
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            addTags({
              groupId: this.groupId,
              title: this.formData.title
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
